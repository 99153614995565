<template>
  <div>
    <el-card v-loading="loading">
      <div class="font-14 font-bold color-303133">
        直播视频号绑定
      </div>
      <div class="font-14 color-999999 flex flex-align-start flex-wrap margin-top-10">
        成功绑定视频号ID，商城小程序中可展示视频号直播间，为视频号直播引流。视频号主体需要与商城小程序主体一致。
        <el-link href="https://jusnn6k8al.feishu.cn/docs/doccnkTHOZa6hbFWTvnwNkCwrSf"
        :underline="false" target="_blank" class="margin-left-10 font-14" style="color:#409eff">查看绑定说明</el-link>
      </div>
      <div class="flex margin-top-10">
        <div class="font-14 line-font-36" style="width:90px;text-align:right">视频号ID：</div>
        <div>
          <div class="flex flex-align-center">
            <el-input v-model="videoform.ChannelShopLiveId" style="width:300px;height:36px;margin-right:20px"
              placeholder=""></el-input>
            <el-button type="primary" style="color:white;font-size:14px;width:96px;" @click="confirmBind('视频号ID绑定成功')">确认绑定
            </el-button>
          </div>
          <div style="margin-top:10px">
            <a href="https://jusnn6k8al.feishu.cn/docs/doccnnlsL1iR8McS3lyfAg5fRid"
              style="font-size:12px;color: #409EEF;" target="_blank">如何获取视频号ID？</a>
          </div>
        </div>
      </div>
      
      <div class="font-14 font-bold color-303133 margin-top-30">
        直播引流入口
      </div>
      <div class="flex margin-top-20">
        <div class="font-14" style="width:120px;text-align:right">直播引流入口：</div>
        <div>
          <div class="flex flex-align-center">
            <el-checkbox-group v-model="videoform.ChannelShopLiveDrainageInletType">
              <el-checkbox :label="1">商品详情页</el-checkbox>
              <el-checkbox :label="2">多人拼团活动页
                <el-popover placement="top" width="200" trigger="hover" content="多人拼团活动页包含：好友参团页面、拼团结果页面">
                  <i class="el-icon-warning" style="color:#C0C4CC" slot="reference"></i>
                </el-popover>
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="font-12 color-999999">
            <div class="flex flex-align-center margin-top-5">
              <div style="margin-top:2px" class="margin-right-10">1.直播间预告、进入直播间的入口展示的页面</div>
              <el-popover placement="bottom" trigger="click">
                <div class="flex flex-justify-between" style="width:450px">
                  <div>
                    <div class="font-14 color-606266 margin-bottom-10">商品详情页</div>
                    <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/5.0.0livebind2.png" style="width:180px" alt="">
                  </div>
                  <div>
                    <div class="font-14 color-606266 margin-bottom-10">多人拼团活动页</div>
                    <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/5.0.0livebind1.png" style="width:180px" alt="">
                  </div>
                </div>
                <el-button type="text" slot="reference" class="font-12" style="margin:0px;padding:0px">查看示例</el-button>
              </el-popover>
            </div>
            <div class="flex flex-align-center margin-top-5">
              <div style="margin-top:2px" class="margin-right-10">2.你还可以通过商城装修，自定义视频号直播引流入口展示。</div>
              <a href="https://jusnn6k8al.feishu.cn/docx/doxcnuinPhhqyPsClCa0Wzj9dAe"
                style="font-size:12px;color: #409EEF;" target="_blank">查看模块装修说明</a>
            </div>
            <div class="flex flex-align-center margin-top-5">
              <div style="margin-top:2px" class="margin-right-10">3.已绑定的视频号ID主体与商城小程序主体不一致，入口无法显示</div>
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <div class="bottom-box">
      <el-button type="primary" style="margin-left:5%" @click="confirmBind('操作成功')">保存</el-button>
    </div>
  </div>
</template>

<script>
import {
  channelShopchannelShopLiveConfig,
  channelShopchannelShopLiveConfigSave
} from "@/api/goods"
export default {
  data () {
    return {
      loading:false,
      videoform:{
        ChannelShopLiveId:'',
        ChannelShopLiveDrainageInletType:[]
      }
    }
  },
  mounted () {
    this.getvideoform()
  },
  methods: {
    async confirmBind(tip){
      if(!this.videoform.ChannelShopLiveId){
        this.$message.error('请输入视频号ID')
        return
      }
      try{
        this.loading = true
        let res = await channelShopchannelShopLiveConfigSave(this.videoform)
        if(res.IsSuccess){
          this.$message.success(tip)
        }
      }finally{
        this.loading = false
      }
    },  
    async getvideoform(){
      try{
        this.loading = true
        let res = await channelShopchannelShopLiveConfig()
        if(res.IsSuccess){
          this.videoform = res.Result
        }
      }finally{
        this.loading = false
      }
    },
  }
}
</script>

<style lang='less' scoped>

.bottom-box{
	position: fixed;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 80px;
	// border:1px solid black;
	background: white;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 99;
}
</style>